import { FunctionComponent, useEffect } from 'react';
import { H } from 'highlight.run';
import { APP_ENV, HIGHLIGHT_KEY } from 'config';

interface ContainerComponentProps {
  children?: JSX.Element | JSX.Element[];
}

const AppWrapper: FunctionComponent<ContainerComponentProps> = ({
  children,
}) => {
  useEffect(() => {
    if (APP_ENV === 'qa') return;
    H.init(HIGHLIGHT_KEY, {
      environment: APP_ENV,
      networkRecording: {
        recordHeadersAndBody: true,
      },
    });
  }, []);

  return <>{children}</>;
};

export default AppWrapper;
