import { DesktopLayoutBoxed } from '@kanda-libs/ks-design-library';

import type { PageProps } from '..';

const Desktop = ({ children }: PageProps) => (
  <DesktopLayoutBoxed help backgroundLines>
    {children}
  </DesktopLayoutBoxed>
);

Desktop.displayName = 'Apply-ErrorPage-Desktop-view';

export default Desktop;
