import { ErrorBoundary } from '@highlight-run/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AmplitudeProvider } from '@kanda-libs/ks-amplitude-provider';
import { AppWrapper, KandaErrorBoundary, Maintenance } from './components';

import Pages from './pages';

interface AppProps {
  maintenance?: boolean;
}

function App({ maintenance = false }: AppProps) {
  if (maintenance) return <Maintenance />;
  return (
    <AmplitudeProvider>
      <ErrorBoundary>
        <KandaErrorBoundary>
          <AppWrapper>
            <Router>
              <Pages />
            </Router>
          </AppWrapper>
        </KandaErrorBoundary>
      </ErrorBoundary>
    </AmplitudeProvider>
  );
}

export default App;
