// eslint-disable-next-line
import { cleanArray } from './Array-functions';

/**
 * Checks if the input is an empty object
 *
 * @param {Any} input
 */
export const isEmptyObject = (input) => {
  if (!(input instanceof Object)) return false;
  if (input instanceof Array) return false;
  return Object.keys(input).length === 0;
};

/**
 * Checks if the input is an empty array
 *
 * @param {Any} input
 */
export const isEmptyArray = (input) => {
  if (!(input instanceof Object)) return false;
  if (!(input instanceof Array)) return false;
  return input.length === 0;
};

/**
 * Cleans the JS object.
 *
 * Any field with empty string or null values are removed from the object. This
 * was developed for cleaning the API request payload.
 *
 * @param {Object} inputObject
 */
export const cleanObject = (object) =>
  Object.keys(object).reduce((cleaned, key) => {
    if (
      object[key] &&
      typeof object[key] === 'object' &&
      !Array.isArray(object[key]) &&
      !(object[key] instanceof Date)
    ) {
      const newObject = cleanObject(object[key]);
      if (isEmptyObject(newObject)) return cleaned;
      return {
        ...cleaned,
        [key]: newObject,
      };
    }
    if (
      object[key] &&
      typeof object[key] === 'object' &&
      Array.isArray(object[key])
    ) {
      const newArray = cleanArray(object[key]);
      if (isEmptyArray(newArray)) return cleaned;
      return {
        ...cleaned,
        [key]: newArray,
      };
    }
    if (
      !(object[key] instanceof Date) &&
      (object[key] === '' ||
        object[key] === null ||
        object[key] === undefined ||
        isEmptyObject(object[key]) ||
        isEmptyArray(object[key]) ||
        Number.isNaN(object[key]))
    ) {
      return cleaned;
    }

    return {
      ...cleaned,
      [key]: object[key],
    };
  }, {});
