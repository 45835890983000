/**
 * Conifguration related to the app
 */
export const URLS = {
  HOME: '/',
  APPLY: '/apply',
  DEPOSIT: '/deposit/:status?',
  CHOOSE_PACKAGE: '/choose-your-package',
};

export const APP_ENV = process.env.REACT_APP_ENV || 'qa';
