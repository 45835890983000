import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSubmit, services } from '@kanda-libs/ks-frontend-services';
import { useToast } from '@kanda-libs/ks-design-library';
import {
  decodePayload,
  escapeParsedPayload,
  formatPayload,
  parseError,
} from 'utils';
import useQuery from './useQuery';

interface ApplyHook {
  isLoading: boolean;
  id?: string;
  error?: string;
}

/**
 * Hook to fetch the current company
 */
export default function useApply(): ApplyHook {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const { showError } = useToast();

  const { queries } = useQuery();
  const signedRequest = queries?.signed_request;

  const payload = useMemo(() => {
    if (!signedRequest) return null;
    const decoded = decodePayload(signedRequest);
    if (!decoded?.payload) return null;
    const escapedPayload = escapeParsedPayload(decoded?.payload);
    return escapedPayload;
  }, [signedRequest]);

  const id = useMemo(() => {
    if (!payload) return null;
    return payload?.enterprise_id;
  }, [payload]);

  const { submit: getUrl } = useSubmit(
    services.infoRedirect.infoLegacyRedirect,
  );

  const getRedirectURLAndPush = useCallback(
    (inputPayload) => {
      const body = formatPayload(inputPayload);
      getUrl({ body }).then(({ data: urlResponse, error: urlError }) => {
        if (urlError) {
          setIsLoading(false);
          showError('Error generating finance application link');
          const err = parseError(urlError);
          setError(err?.message);
          return;
        }
        const url = urlResponse?.redirect_urls?.checkout_url;
        if (!url) {
          setIsLoading(false);
          showError('Error generating finance application link');
          return;
        }
        window.location.replace(url);
      });
    },
    [getUrl, showError],
  );

  const urlRef = useRef(false);
  useEffect(() => {
    if (!payload) return;
    if (urlRef.current) return;
    getRedirectURLAndPush(payload);
    urlRef.current = true;
  }, [payload, getRedirectURLAndPush]);

  return {
    isLoading,
    error,
    id,
  };
}
