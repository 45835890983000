import type { StringIndexedObject } from '@kanda-libs/ks-frontend-services/src/types';

const parseQueryString = (queryString: string): StringIndexedObject => {
  const qs = queryString.substring(1);
  const vars = qs.split('&');
  return vars.reduce((variables: StringIndexedObject, variable: string) => {
    const parts = variable.split('=');
    return {
      ...variables,
      [parts[0]]: parts[1],
    };
  }, {});
};

interface QueryHook {
  queries: StringIndexedObject;
}

/**
 * Hook to fetch the current company
 */
export default function useQuery(): QueryHook {
  const queryString = window.location.search;
  const queries = parseQueryString(queryString);

  return {
    queries,
  };
}
