import { useEffect, useRef } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useAmplitude } from '@kanda-libs/ks-amplitude-provider';

import Apply from './Apply';
import ChooseYourPackage from './ChooseYourPackage';
import Deposit from './Deposit';
import Home from './Home';

import config from '../config';

const PageArray = {
  [config.URLS.APPLY]: Apply,
  [config.URLS.CHOOSE_PACKAGE]: ChooseYourPackage,
  [config.URLS.DEPOSIT]: Deposit,
  [config.URLS.HOME]: Home,
};

interface WindowWithHelpCrunch extends Window {
  HelpCrunch: (action: string) => void;
}

const Pages = ({}) => {
  const location = useLocation();

  const { logEvent } = useAmplitude();

  const { pathname, search } = window.location;

  // Effect hides chat widget on route changes
  useEffect(() => {
    const windowWithHelpCrunch = window as unknown as WindowWithHelpCrunch;

    if (!windowWithHelpCrunch.HelpCrunch) return;
    setTimeout(() => {
      windowWithHelpCrunch.HelpCrunch('hideChatWidget');
    }, 120);
  }, [location]);

  const pathNameRef = useRef('');
  const searchRef = useRef('');
  useEffect(() => {
    if (pathname === pathNameRef.current && search === searchRef.current)
      return;
    pathNameRef.current = pathname;
    searchRef.current = search;
    logEvent('page-view');
  }, [pathname, search, logEvent]);

  return (
    <Switch>
      {Object.entries(PageArray).map(([path, Page]) => (
        <Route path={path} key={path}>
          <Page />
        </Route>
      ))}
    </Switch>
  );
};

export default Pages;
