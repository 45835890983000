import { BreakPoints } from '@kanda-libs/ks-design-library';

import Mobile from './Mobile';
import Desktop from './Desktop';

export interface PageProps {
  children: JSX.Element | undefined;
}

const Page = ({ children }: PageProps) => (
  <BreakPoints
    mobile={<Mobile>{children}</Mobile>}
    desktop={<Desktop>{children}</Desktop>}
  />
);

Page.displayName = 'ErrorPage-view';

export default Page;
