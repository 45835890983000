import * as application from './application';
import * as enterprise from './enterprise';
import * as integrations from './integrations';
import * as urls from './urls';

export * from './application';
export * from './enterprise';
export * from './integrations';
export * from './urls';

const Default = {
  ...application,
  ...enterprise,
  ...integrations,
  ...urls,
};

export default Default;
