import { useEffect } from 'react';
import { useQuery } from 'hooks';
import { useHistory } from 'react-router-dom';
import { URLS } from 'config';

const ChooseYourPackage = () => {
  const { queries } = useQuery();
  const { push } = useHistory();

  useEffect(() => {
    const signedRequest = queries?.signed_request || 'l.p';
    push(`${URLS.APPLY}?signed_request=${signedRequest}`);
  }, [push, queries]);
  return null;
};

export default ChooseYourPackage;
