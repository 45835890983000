import { useApply } from 'hooks';
import { Icon, Text } from '@kanda-libs/ks-design-library';

import Page from './Page';
import { TEXT } from './constants';

const Apply = () => {
  const { isLoading, id, error } = useApply();

  if (isLoading || !error) return null;
  return (
    <Page>
      <div className="flex flex-col w-full max-w-120 m-auto">
        <Icon
          size={104}
          stroke="red-200"
          icon="error"
          className="mx-auto mb-6"
        />
        <Text text={TEXT.header} className="text-24-28-em mb-4 text-center" />
        <Text text={TEXT.subHeader} className="text-center mb-4" />
        <Text text={`ID: ${id}`} className="text-center text-16-20-em" />
        <Text text={`Error: ${error}`} className="text-center text-16-20-em" />
      </div>
    </Page>
  );
};

export default Apply;
