import { Logo, Header as MobileHeader } from '@kanda-libs/ks-design-library';

const Header = () => (
  <MobileHeader.Base className="text-style-f-em text-neutral-700" help>
    <Logo />
  </MobileHeader.Base>
);

Header.displayName = 'Apply-Mobile-Header-view';

export default Header;
