import { Layout } from '@kanda-libs/ks-design-library';

import Header from './Header';
import type { PageProps } from '..';

const Mobile = ({ children }: PageProps) => (
  <Layout header={<Header />} footer={undefined} noBorder>
    <div className="-mt-12 flex flex-1">{children}</div>
  </Layout>
);

Mobile.displayName = 'Apply-ErrorPage-Mobile-view';

export default Mobile;
